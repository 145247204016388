//Angular variables
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';

// Other external imports
import { DateTime } from 'luxon';

//Internal services
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';
import { BaseHttpService } from '../base-http-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

// Internal models
import { LoginDetailsResponse } from 'src/app/models/auth/responses/login-details.response';
import { RefreshedSessionResponse } from 'src/app/models/auth/responses/refreshed-session.response';
import { CurrentVenueISOTimeObject } from 'src/app/models/venues/objects/current-venue-iso-time.object';

//Internal enums
import { EmployeeRole } from 'src/app/enums/account/employee-role.enum';
import { UserTokens } from 'src/app/models/auth/objects/user-tokens.object';
import { VenueEmployeePermissionDto } from 'src/app/models/auth/objects/venue-employee-permission.dto';

export interface LoginBody {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface NewEmployeeBody {
  employeeName: string;
  employeeEmail: string;
  employeeType: string;
  venueUserId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseHttpService {
  public profilePictureUrlObservable = new Subject<string>();
  public userIdObservable = new Subject<string>();
  public corporationIsRangeObservable = new Subject<boolean>();
  public corporationIsCourseObservable = new Subject<boolean>();
  public corporationChargesDueUpfrontObservable = new Subject<boolean>();
  public corporationRequiresDueUpfrontObservable = new Subject<boolean>();
  public corporationChargesUponCheckInObservable = new Subject<boolean>();
  public employeePermissionsObservable = new Subject<VenueEmployeePermissionDto>();

  private userTokens = new UserTokens(null);
  private userPermissions: VenueEmployeePermissionDto;
  private email: string;

  // corporation details
  private corporationUserId: string;
  private corporationIconImageId: string;
  private corporationCoverImageId: string;
  private isParentCorporationEmployee: boolean;
  private corporationIsParentCorporation: boolean;
  private parentCorporationId: string;
  private corporationIsRange: boolean;
  private corporationIsCourse: boolean;
  private corporationTimeZone: string;
  private corporationChargesDueUpfront: boolean;
  private corporationRequiresDueUpfront: boolean;
  private corporationChargesUponCheckIn: boolean;
  private corporationMaxBaysPerReservation: number;
  private corporationMaxPeoplePerBay: number;
  private corporationEventMaxBaysPerReservation: number;
  private corporationEventMaxPeoplePerBay: number;
  private venueLocalOpenTime: string;
  private venueLocalCloseTime: string;
  private currentVenueTime: string;
  private venueTimeLastRequestedUtc: DateTime | null;

  // user details
  private userIsSuperAdmin: boolean;
  private profilePictureUrl: string;
  private employeeRole: EmployeeRole;

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  hasValidIdToken(): boolean {
    return (this.userTokens != null && this.userTokens.accessToken != null);
  }

  authorizationHeader(): string {
      return 'BEARER ' + this.userTokens.accessToken;
  }

  accessToken(): string {
      return this.userTokens.accessToken;
  }

  async userId(): Promise<string> {
      return this.userTokens.userId;
  }

  async setUserId(userId: string) {
    this.userTokens.userId = userId;
  }

  async name(): Promise<string> {
    return this.userTokens.name;
  }

  userIdentifier(): number {
      return this.userTokens.userIdentifier;
  }

  async userEmail(): Promise<string> {
    return this.email;
  }

  async userProfilePictureUrl(): Promise<string> {
    return this.profilePictureUrl;
  }

  async setProfilePictureUrl(url: string) {
    this.profilePictureUrl = url;
    this.profilePictureUrlObservable.next(url);
  }

  // venueUserId(): string {
  //   return this.corporationUserId;
  // }


  // isCorporationEmployee(): boolean {
  //   return this.isParentCorporationEmployee;
  // }

  async setUserTokens(token) {
    this.userTokens = token;

    if (!!token && token.userId !== null && token.userId !== undefined && token.userId !== '' && token.userId !== 'null') {
      this.userIdObservable.next(token.userId);
    } else {
      this.userIdObservable.next(null);
    }
  }

  async getUserTokens(): Promise<UserTokens> {
    return this.userTokens;
  }

  async setUserPermissions(permissions: VenueEmployeePermissionDto) {
    this.userPermissions = permissions;
    this.employeePermissionsObservable.next(permissions);
  }

  async getUserPermissions(): Promise<VenueEmployeePermissionDto> {
    return this.userPermissions;
  }

  //this does not include access or refresh tokens
  async setRefreshedUserTokens(token) {
    this.userTokens.userId = token.userId;
    this.userTokens.corporationDetailId = token.corporationDetailId;
    this.userTokens.corporationUserId = token.corporationUserId;
    this.userTokens.currentVenueTime = token.currentVenueTime;
    this.userTokens.userIdentifier = token.userIdentifier;
    this.userTokens.name = token.name;
  }

  setUserEmail(passedEmail: string) {
    this.email = passedEmail;
  }

  async setUserRole(role: EmployeeRole) {
    this.employeeRole = role;
  }
  async getUserRole() {
    return this.employeeRole;
  }

  async setLoginDetails(loginDetails: LoginDetailsResponse) {
    await Promise.all([
      this.setUserTokens(loginDetails.tokens),
      this.setCorporationUserId(loginDetails.corporationDetails.corporationUserId),
      this.setCorporationIsParentCorporation(loginDetails.corporationDetails.corporationIsParentCorporation),
      this.setParentCorporationId(loginDetails.corporationDetails.parentCorporationId),
      this.setCorporationIconImageId(loginDetails.corporationDetails.corporationIconImageId),
      this.setCorporationCoverImageId(loginDetails.corporationDetails.corporationCoverImageId),
      this.setCorporationIsRange(loginDetails.corporationDetails.corporationIsRange),
      this.setCorporationIsCourse(loginDetails.corporationDetails.corporationIsCourse),
      this.setCorporationChargesDueUpfront(loginDetails.corporationDetails.corporationChargesDueUpfront),
      this.setCorporationRequiresDueUpfront(loginDetails.corporationDetails.corporationRequiresDueUpfront),
      this.setCorporationChargesUponCheckIn(loginDetails.corporationDetails.corporationChargesUponCheckIn),
      this.setCorporationMaxBaysPerReservation(loginDetails.corporationDetails.corporationMaxBaysPerReservation),
      this.setCorporationMaxPeoplePerBay(loginDetails.corporationDetails.corporationMaxPeoplePerBay),
      this.setCorporationEventMaxBaysPerReservation(loginDetails.corporationDetails.corporationEventMaxBaysPerReservation),
      this.setCorporationEventMaxPeoplePerBay(loginDetails.corporationDetails.corporationEventMaxPeoplePerBay),
      this.setVenueLocalOpenTime(loginDetails.corporationDetails.venueLocalOpenTime),
      this.setVenueLocalCloseTime(loginDetails.corporationDetails.venueLocalCloseTime),
      this.setUserEmail(loginDetails.userEmail),
      this.setUserIsSuperAdmin(loginDetails.userIsSuperAdmin),
      this.setIsParentCorporationEmployee(loginDetails.employeeIsParentCorporationEmployee),
      this.setCorporationTimeDetails(loginDetails.tokens.currentVenueTime.timeZone, loginDetails.tokens.currentVenueTime.currentTime, loginDetails.tokens.currentVenueTime.lastRequestedUtc)
    ]);

    return true;
  }


  async setRefreshSessionDetails(refreshSessionDetails: RefreshedSessionResponse) {
    await Promise.all([
      this.setRefreshedUserTokens(refreshSessionDetails.tokens),
      this.setCorporationUserId(refreshSessionDetails.corporationDetails.corporationUserId),
      this.setCorporationIsParentCorporation(refreshSessionDetails.corporationDetails.corporationIsParentCorporation),
      this.setParentCorporationId(refreshSessionDetails.corporationDetails.parentCorporationId),
      this.setCorporationIconImageId(refreshSessionDetails.corporationDetails.corporationIconImageId),
      this.setCorporationCoverImageId(refreshSessionDetails.corporationDetails.corporationCoverImageId),
      this.setCorporationIsRange(refreshSessionDetails.corporationDetails.corporationIsRange),
      this.setCorporationIsCourse(refreshSessionDetails.corporationDetails.corporationIsCourse),
      this.setCorporationChargesDueUpfront(refreshSessionDetails.corporationDetails.corporationChargesDueUpfront),
      this.setCorporationRequiresDueUpfront(refreshSessionDetails.corporationDetails.corporationRequiresDueUpfront),
      this.setCorporationChargesUponCheckIn(refreshSessionDetails.corporationDetails.corporationChargesUponCheckIn),
      this.setCorporationMaxBaysPerReservation(refreshSessionDetails.corporationDetails.corporationMaxBaysPerReservation),
      this.setCorporationMaxPeoplePerBay(refreshSessionDetails.corporationDetails.corporationMaxPeoplePerBay),
      this.setCorporationEventMaxBaysPerReservation(refreshSessionDetails.corporationDetails.corporationEventMaxBaysPerReservation),
      this.setCorporationEventMaxPeoplePerBay(refreshSessionDetails.corporationDetails.corporationEventMaxPeoplePerBay),
      this.setVenueLocalOpenTime(refreshSessionDetails.corporationDetails.venueLocalOpenTime),
      this.setVenueLocalCloseTime(refreshSessionDetails.corporationDetails.venueLocalCloseTime),
      this.setUserEmail(refreshSessionDetails.userEmail),
      this.setUserIsSuperAdmin(refreshSessionDetails.userIsSuperAdmin),
      this.setIsParentCorporationEmployee(refreshSessionDetails.employeeIsParentCorporationEmployee),
      this.setCorporationTimeDetails(refreshSessionDetails.tokens.currentVenueTime.timeZone, refreshSessionDetails.tokens.currentVenueTime.currentTime, refreshSessionDetails.tokens.currentVenueTime.lastRequestedUtc)
    ]);

    return true;
  }


  async setCorporationUserId(passedCorporationUserId: string) {
    this.corporationUserId = passedCorporationUserId;
  }

  async getCorporationUserId() {
    return this.corporationUserId;
  }


  async setCorporationIconImageId(passedImageId: string) {
    this.corporationIconImageId = passedImageId;
  }

  async getCorporationIconImageId() {
    return this.corporationIconImageId;
  }


  async setCorporationCoverImageId(passedImageId: string) {
    this.corporationCoverImageId = passedImageId;
  }

  async getCorporationCoverImageId() {
    return this.corporationCoverImageId;
  }


  async setIsParentCorporationEmployee(isCorporationEmployee: boolean) {
    this.isParentCorporationEmployee = isCorporationEmployee;
  }

  async getIsParentCorporationEmployee() {
    return this.isParentCorporationEmployee;
  }


  async setCorporationIsParentCorporation(isParentCorporationStatus: boolean) {
    this.corporationIsParentCorporation = isParentCorporationStatus;
  }

  async getCorporationIsParentCorporation() {
    return this.corporationIsParentCorporation;
  }


  async setParentCorporationId(parentCorporationId: string) {
    this.parentCorporationId = parentCorporationId;
  }

  async getParentCorporationId() {
    return this.parentCorporationId;
  }


  async setCorporationTimeDetails(timeZone: string, currentVenueTime: string, lastRequestedUtc: DateTime | null) {
    this.corporationTimeZone = timeZone;
    this.currentVenueTime = currentVenueTime;
    this.venueTimeLastRequestedUtc = lastRequestedUtc;
  }

  async getCorporationTimeDetails(): Promise<CurrentVenueISOTimeObject> {
    return {
      timeZone: this.corporationTimeZone,
      currentTime: this.currentVenueTime,
      lastRequestedUtc: this.venueTimeLastRequestedUtc
    };
  }

  async getCurrentVenueTime() {
    return this.currentVenueTime;
  }

  async setUserIsSuperAdmin(userIsSuperAdmin: boolean) {
    this.userIsSuperAdmin = userIsSuperAdmin;
  }

  async getUserIsSuperAdmin() {
    return this.userIsSuperAdmin;
  }

  async setCorporationIsRange(isRange: boolean) {
    this.corporationIsRange = isRange;
    this.corporationIsRangeObservable.next(isRange);
  }

  async getCorporationIsRange() {
    return this.corporationIsRange;
  }

  async setCorporationIsCourse(isCourse: boolean) {
    this.corporationIsCourse = isCourse;
    this.corporationIsCourseObservable.next(isCourse);
  }

  async setCorporationChargesDueUpfront(chargesDueUpfront: boolean) {
    this.corporationChargesDueUpfront = chargesDueUpfront;
    this.corporationChargesDueUpfrontObservable.next(chargesDueUpfront);
  }

  async getCorporationChargesDueUpfront() {
    return this.corporationChargesDueUpfront;
  }

  async setCorporationRequiresDueUpfront(requiresDueUpfront: boolean) {
    this.corporationRequiresDueUpfront = requiresDueUpfront;
    this.corporationRequiresDueUpfrontObservable.next(requiresDueUpfront);
  }

  async getCorporationRequiresDueUpfront() {
    return this.corporationRequiresDueUpfront;
  }

  async setCorporationChargesUponCheckIn(chargesUponCheckIn: boolean) {
    this.corporationChargesUponCheckIn = chargesUponCheckIn;
    this.corporationChargesUponCheckInObservable.next(chargesUponCheckIn);
  }

  async setCorporationMaxBaysPerReservation(maxBaysPerReservation: number) {
    this.corporationMaxBaysPerReservation = maxBaysPerReservation;
  }

  async getCorporationMaxBaysPerReservation() {
    return this.corporationMaxBaysPerReservation;
  }

  async setCorporationMaxPeoplePerBay(maxPeoplePerBay: number) {
    this.corporationMaxPeoplePerBay = maxPeoplePerBay;
  }

  async getCorporationMaxPeoplePerBay() {
    return this.corporationMaxPeoplePerBay;
  }

  async setCorporationEventMaxBaysPerReservation(maxBaysPerReservation: number) {
    this.corporationEventMaxBaysPerReservation = maxBaysPerReservation;
  }

  async getCorporationEventMaxBaysPerReservation() {
    return this.corporationEventMaxBaysPerReservation;
  }

  async setCorporationEventMaxPeoplePerBay(maxPeoplePerBay: number) {
    this.corporationEventMaxPeoplePerBay = maxPeoplePerBay;
  }

  async getCorporationEventMaxPeoplePerBay() {
    return this.corporationEventMaxPeoplePerBay;
  }

  async getCorporationBayGuardRails() {
    return {
      maxBaysPerReservation: this.corporationMaxBaysPerReservation,
      maxPeoplePerBay: this.corporationMaxPeoplePerBay,
      eventMaxBaysPerReservation: this.corporationEventMaxBaysPerReservation,
      eventMaxPeoplePerBay: this.corporationEventMaxPeoplePerBay
    };
  }

  async setVenueLocalOpenTime(venueLocalOpenTime: string) {
    this.venueLocalOpenTime = venueLocalOpenTime;
  }

  async getVenueLocalOpenTime() {
    return this.venueLocalOpenTime;
  }

  async setVenueLocalCloseTime(venueLocalCloseTime: string) {
    this.venueLocalCloseTime = venueLocalCloseTime;
  }

  async getVenueLocalCloseTime() {
    return this.venueLocalCloseTime;
  }

  async getCorporationChargesUponCheckIn() {
    return this.corporationChargesUponCheckIn;
  }

  async getCorporationIsCourse() {
    return this.corporationIsCourse;
  }

  async getCorporationDetailId() {
    return this.userTokens.corporationDetailId;
  }


  // ! POST Requests
  async resetPassword(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Auth/ResetPasswordMobile`;
    return this.post<any>(endpoint, body, retry);
  }

  async sendForgotPassword(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Account/ForgotPasswordMobile`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getRefreshSessionData(retry = true): Promise<RefreshedSessionResponse | null> {
    const endpoint = `/api/Account/RefreshSessionData`;
    return this.get<any>(endpoint, retry);
  }
  
  // ! PUT Requests

}
