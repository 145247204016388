// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';
import { SelectBayMobileDto } from '../models/assignment/objects/select-bay-mobile.dto';

//Internal models


@Injectable()
export class AssignmentProvider {
    //selected calendar time interval variables
    selectingBayMobile: boolean = false;
    selectingBayMobileDto: SelectBayMobileDto;
    selectingBayMobileObservable = new Subject<SelectBayMobileDto>();
    selectingBayMobileSelectionUpdatedObservable = new Subject<SelectBayMobileDto>();

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    setSelectingBayMobile(value: SelectBayMobileDto) {
        this.selectingBayMobile = value.selecting;
        this.selectingBayMobileDto = value;
        this.selectingBayMobileObservable.next(value);
    }
    updateSelectedBayCount(value: SelectBayMobileDto) {
        this.selectingBayMobileDto = value;
        this.selectingBayMobileSelectionUpdatedObservable.next(value);
    }
    async getSelectingBayMobile(): Promise<SelectBayMobileDto> {
        return this.selectingBayMobileDto;
    }
}