//Angular variables
import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async getShiftsByDate(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Scheduling/GetShiftsByDate`;
    return this.post<any>(endpoint, body, retry);
  }
  
  // ! GET Requests
  
  // ! PUT Requests

  // ! DELETE Requests
}
