import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

// Capacitor imports
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

// Ionic imports
import { Platform } from '@ionic/angular';

// Internal providers
import { AccountProvider } from './providers/account.provider';
import { GlobalAppProvider } from './app.provider';

// Internal services
import { AccountService } from './services/account/account.service';
import { AuthService } from './services/auth-service/auth.service';
import { SignalrService } from './services/signalr-service/signalr.service';

// Internal models
import { RefreshedSessionResponse } from './models/auth/responses/refreshed-session.response';
import { VenueEmployeePermissionDto } from './models/auth/objects/venue-employee-permission.dto';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isInitialized = false;
  initialNavigation: string | null = null;

  constructor(
    private accountProvider: AccountProvider,
    private accountService: AccountService,
    private appProvider: GlobalAppProvider,
    private authService: AuthService,
    private platform: Platform,
    private router: Router,
    private signalrService: SignalrService
  ) {
    this.setStatusBarColor();
    this.initializeApp();

    // Listen to router events to capture initial navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !this.initialNavigation) {
        this.initialNavigation = event.url;
      }
    });
  }

  async ngOnInit() {
    await this.appProvider.presentGlobalLoading('Loading...');

    this.authService.isAuthenticated$
      .subscribe(async (isAuthenticated) => {
        if (!isAuthenticated) {
          if (await this.isValidUnauthenticatedRoute(this.initialNavigation)) {
            // set current page
            localStorage.setItem('currentPage', this.initialNavigation);
            return;
          } else {
            this.router.navigate(['/login']);
          }
        }
      });

    await this.authService.initializeAuth();
    this.isInitialized = true;

    const accessToken = this.authService.getAccessToken();

    if (accessToken && !this.authService.isAccessTokenExpired()) {
      // Optionally, navigate to home or desired route
      await this.getRefreshSessionData()
        .then(async (refreshResponse: RefreshedSessionResponse | null) => {
          if (refreshResponse) {
            // const currentPage = localStorage.getItem('currentPage');

            // const navigateTo = currentPage && currentPage !== '/login' ? currentPage : '/home';
  
            // const validatedNavigateTo = await this.validateNavigateTo(navigateTo);
  
            // await this.router.navigate([validatedNavigateTo])
            //   .then(async () => {
            //     localStorage.setItem('currentPage', validatedNavigateTo);
            //     this.appProvider.setTokenRefreshed(true);
            //   })
            //   .catch(async (error) => {
            //     await this.appProvider.dismissGlobalLoading();
            //     console.error(error);
            //   });
            await this.navigateToDefaultPage(refreshResponse.permissions);
          }
        })
        .catch(async (error) => {
          await this.appProvider.dismissGlobalLoading();
          console.error(error);
        })
    } else {
      // No valid access token, ensure user is on login page
      if (await this.isValidUnauthenticatedRoute(this.initialNavigation)) {
        await this.appProvider.dismissGlobalLoading();
        return;
      } else {
        await this.appProvider.dismissGlobalLoading();
        this.router.navigate(['/login']);
      }
    }
  }

  async getDefaultPage(employeePermissions: VenueEmployeePermissionDto) {
    switch(true) {
      case employeePermissions.hasAdminAccess || employeePermissions.canViewAnalytics:
        return '/home'; // TODO: change home to analytics page
      case employeePermissions.canViewReservations:
        return '/reservations';
      case employeePermissions.canViewFinancials:
        return '/accounting';
      case employeePermissions.canViewEvents:
        return '/events';
      case employeePermissions.canViewCustomers:
        return '/crm';
      case employeePermissions.canViewLeagues:
        return '/leagues';
      case employeePermissions.canViewTournaments:
        return '/tournaments';
      default:
        return '/access-denied';
    }
  }

  async navigateToDefaultPage(employeePermissions: VenueEmployeePermissionDto) {
    const now = new Date().toISOString();
    const currentPage = localStorage.getItem('currentPage');
    const defaultPage = await this.getDefaultPage(employeePermissions);

    const navigateTo = currentPage && currentPage !== '/login' ? currentPage : defaultPage;
    
    const validatedNavigateTo = await this.validateNavigateTo(navigateTo);

    this.router.navigate([validatedNavigateTo], { queryParams: { d: now }})
      .then(async () => {
        localStorage.setItem('currentPage', validatedNavigateTo);
        this.appProvider.setTokenRefreshed(true);
      })
      .catch(async (error) => {
        await this.appProvider.dismissGlobalLoading();
        console.error(error);
      })
  }

  async validateNavigateTo(page: string) {
    switch(page) {
      case '/home':
        return '/home';
      case '/reservations':
        return '/reservations';
      case '/leagues':
        return '/leagues';
      default:
        return '/home';
    }
  }

  async isValidUnauthenticatedRoute(route: string): Promise<boolean> {
    const validRoutes = [
      '/login',
      '/super-admin',
      '/reset-password',
      '/forgot-password',
      '/privacy-policy',
      '/terms-of-service',
      '/end-user-agreement',
      '/access-denied'
    ];
    
    return validRoutes.some(validRoute => route.startsWith(validRoute));
  }
  

  initializeApp() {
    this.platform.ready().then(() => {
      // Any platform-specific initialization can go here
      // SignalRService connection is already started in its constructor
    });

    // Listen to platform pause and resume events if needed
    this.platform.pause.subscribe(() => {
      // Handle app pause
      // this.signalrService.stopConnection();
    });

    this.platform.resume.subscribe(() => {
      // Handle app resume (optional)
      // Restart the signalr connection if it was stopped
      // this.signalrService.startConnection();
    });
  }

  async getRefreshSessionData(): Promise<RefreshedSessionResponse | null> {
    const refreshResponse = await this.accountService.getRefreshSessionData();

    await Promise.all([
      this.authService.setRefreshUserTokens(refreshResponse.tokens),
      this.accountService.setUserPermissions(refreshResponse.permissions),
      this.accountService.setRefreshSessionDetails(refreshResponse),
      this.accountProvider.setSelectableCorporationDetails(refreshResponse.selectableChildCorporations)
    ]);

    return refreshResponse;
  }

  async setStatusBarColor() {
    if (this.isNative()) {
      await StatusBar.setBackgroundColor({color: '#007fac'});
    }
  };

  private isNative(): boolean {
    return Capacitor.isNativePlatform();
  }
}
