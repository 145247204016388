import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class LeagueService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async upsertLeagueAsync(body: object, isEditing: boolean, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/Upsert/${isEditing}`;
    return this.post<any>(endpoint, body, retry);
  }

  async createLeagueReferralCodeAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/CreateReferralCode`;
    return this.post<any>(endpoint, body, retry);
  }

  async createLeagueReferralRewardAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/CreateReferralReward`;
    return this.post<any>(endpoint, body, retry);
  }

  async createLeagueDiscountAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/CreateDiscount`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getAllLeaguesByCorpDetailId(corpDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corpDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getFilteredLeaguesByCorpDetailId(corpDetailId: string, filter: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corpDetailId}/filter/${filter}`;
    return this.get<any>(endpoint, retry);
  }

  async getSearchedLeaguesByCorpDetailId(searchText: string, corpDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corpDetailId}/search/${searchText}`;
    return this.get<any>(endpoint, retry);
  }

  async getFilteredAndSearchedLeaguesByCorpDetailId(corpDetailId: string, filter: string, searchText: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corpDetailId}/${filter}/${searchText}`;
    return this.get<any>(endpoint, retry);
  }

  async getLeagueById(corpDetailId: string, leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corpDetailId}/${leagueId}`;
    return this.get<any>(endpoint, retry);
  }

  async getLeagueRegistrations(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${leagueId}/registrations`;
    return this.get<any>(endpoint, retry);
  }

  async getAllLeagueReferralCodes(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${leagueId}/referralCodes`;
    return this.get<any>(endpoint, retry);
  }

  async getAllLeagueDiscountCodes(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${leagueId}/discountCodes`;
    return this.get<any>(endpoint, retry);
  }

  async getLeagueShortLinkDetails(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${leagueId}/shortLinkDetails`;
    return this.get<any>(endpoint, retry);
  }

  async getSearchedLeagueUsers(corporationDetailId: string, leagueId: string, searchText: string, isEmployee: boolean, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${corporationDetailId}/${leagueId}/searchUsers/${searchText}/${isEmployee}`;
    return this.get<any>(endpoint, retry);
  }

  async getAllLeagueReferralRewards(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Leagues/${leagueId}/referralRewards`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  

}