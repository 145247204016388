// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';

// Internal models
import { LeagueDto } from '../models/leagues/objects/league.dto';


interface ShowAddLeague {
    show: boolean;
    isEditing: boolean;
}

@Injectable()
export class LeaguesProvider {
    // show leagues list variables
    selectedLeague: LeagueDto;
    selectedLeagueObservable = new Subject<LeagueDto>();

    // show add league
    showAddLeague: boolean = false;
    showAddLeagueObservable = new Subject<ShowAddLeague>();
    isEditingLeague: boolean = false;

    // show manage registrations
    showManageRegistrations: boolean = false;
    showManageRegistrationsObservable = new Subject<boolean>();
    showEditRegistration: boolean = false;
    showEditRegistrationObservable = new Subject<boolean>();
    showCustomerDetailsSlideOut: boolean = false;
    showCustomerDetailsSlideOutObservable = new Subject<boolean>();

    // show manage referral codes
    showManageReferralCodes: boolean = false;
    showManageReferralCodesObservable = new Subject<boolean>();

    // show add league referral code
    showAddLeagueReferralCode: boolean = false;
    showAddLeagueReferralCodeObservable = new Subject<boolean>();

    // show add league referral award
    showAddLeagueReferralReward: boolean = false;
    showAddLeagueReferralRewardObservable = new Subject<boolean>();

    // show manage discounts
    showManageDiscounts: boolean = false;
    showManageDiscountsObservable = new Subject<boolean>();

    // show add league discount
    showAddLeagueDiscount: boolean = false;
    showAddLeagueDiscountObservable = new Subject<boolean>();

    // show league calendar
    showLeagueCalendar: boolean = false;
    showLeagueCalendarObservable = new Subject<boolean>();

    // show league leaderboard
    showLeagueLeaderboard: boolean = false;
    showLeagueLeaderboardObservable = new Subject<boolean>();

    constructor(
        private accountService: AccountService,
        private appProvider: GlobalAppProvider,
        public modalCtrl: ModalController
    ) {

    }

    // show leagues list
    async setSelectedLeague(league: LeagueDto) {
        this.selectedLeague = league;
        this.selectedLeagueObservable.next(league);
    }
    async getSelectedLeague(): Promise<LeagueDto> {
        return this.selectedLeague;
    }


    // show add league
    async setShowAddLeague(show: boolean, isEditing: boolean = false) {
        this.showAddLeague = show;
        this.isEditingLeague = isEditing;
        this.showAddLeagueObservable.next({ show: show, isEditing: isEditing });
    }
    async getShowAddLeague(): Promise<ShowAddLeague> {
        return { show: this.showAddLeague, isEditing: this.isEditingLeague };
    }


    // show manage registrations
    async setShowManageRegistrations(show: boolean) {
        this.showManageRegistrations = show;
        this.showManageRegistrationsObservable.next(show);
    }
    async getShowManageRegistrations(): Promise<boolean> {
        return this.showManageRegistrations;
    }

    async setShowEditRegistration(show: boolean) {
        this.showEditRegistration = show;
        this.showEditRegistrationObservable.next(show);
    }
    async getShowEditRegistration(): Promise<boolean> {
        return this.showEditRegistration;
    }

    async setShowCustomerDetailsSlideOut(show: boolean) {
        this.showCustomerDetailsSlideOut = show;
        this.showCustomerDetailsSlideOutObservable.next(show);
    }
    async getShowCustomerDetailsSlideOut(): Promise<boolean> {
        return this.showCustomerDetailsSlideOut;
    }


    // show manage referral codes
    async setShowManageReferralCodes(show: boolean) {
        this.showManageReferralCodes = show;
        this.showManageReferralCodesObservable.next(show);
    }
    async getShowManageReferralCodes(): Promise<boolean> {
        return this.showManageReferralCodes;
    }

    // show add league referral code
    async setShowAddLeagueReferralCode(show: boolean) {
        this.showAddLeagueReferralCode = show;
        this.showAddLeagueReferralCodeObservable.next(show);
    }
    async getShowAddLeagueReferralCode(): Promise<boolean> {
        return this.showAddLeagueReferralCode;
    }

    // show add league referral award
    async setShowAddLeagueReferralReward(show: boolean) {
        this.showAddLeagueReferralReward = show;
        this.showAddLeagueReferralRewardObservable.next(show);
    }
    async getShowAddLeagueReferralReward(): Promise<boolean> {
        return this.showAddLeagueReferralReward;
    }


    // show manage discount codes
    async setShowManageDiscountCodes(show: boolean) {
        this.showManageDiscounts = show;
        this.showManageDiscountsObservable.next(show);
    }
    async getShowManageDiscountCodes(): Promise<boolean> {
        return this.showManageDiscounts;
    }

    // show add league discount
    async setShowAddLeagueDiscount(show: boolean) {
        this.showAddLeagueDiscount = show;
        this.showAddLeagueDiscountObservable.next(show);
    }
    async getShowAddLeagueDiscount(): Promise<boolean> {
        return this.showAddLeagueDiscount;
    }


    // show league calendar
    async setShowLeagueCalendar(show: boolean) {
        this.showLeagueCalendar = show;
        this.showLeagueCalendarObservable.next(show);
    }
    async getShowLeagueCalendar(): Promise<boolean> {
        return this.showLeagueCalendar;
    }


    // show league leaderboard
    async setShowLeagueLeaderboard(show: boolean) {
        this.showLeagueLeaderboard = show;
        this.showLeagueLeaderboardObservable.next(show);
    }
    async getShowLeagueLeaderboard(): Promise<boolean> {
        return this.showLeagueLeaderboard;
    }

}

