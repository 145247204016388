import { Injectable } from '@angular/core';
import { ChatClientService, StreamI18nService, ThemeService } from 'stream-chat-angular';
import { User } from 'stream-chat';

@Injectable({
  providedIn: 'root'
})
export class MessagingWrapperService {
  private apiKey = 'dz5f4d5kzrue';

  constructor(
    private chatClientService: ChatClientService,
    private streamI18nService: StreamI18nService,
    private themeService: ThemeService
  ) { }

  /**
   * Initialize the chat client with dynamic user information.
   * @param user A user object adhering to Stream Chat's User interface.
   * @param token A valid token for the user.
   */
  initChat(user: User, token: string): void {
    // Optionally set the theme. In this example we set the dark theme.
    this.themeService.theme$.next('dark');

    // Initialize Stream Chat client
    this.chatClientService.init(this.apiKey, user, token);

    // Set translations or any i18n requirements.
    this.streamI18nService.setTranslation();
  }
}
