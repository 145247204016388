import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';

// Internal services
import { AccountService } from '../services/account/account.service';
import { AuthService } from '../services/auth-service/auth.service';

// Internal models
import { VenueEmployeePermissionDto } from '../models/auth/objects/venue-employee-permission.dto';

function hasPermissionToAccessRoute(employeePermissions: VenueEmployeePermissionDto, route: ActivatedRouteSnapshot): boolean {
  switch(route.url[0].path) {
    case 'home':
      return employeePermissions.canViewAnalytics;
    case 'accounting':
      return employeePermissions.canViewFinancials;
    case 'reservations':
      return employeePermissions.canViewReservations;
    case 'scheduling':
      return employeePermissions.canViewEmployees;
    case 'events':
      return employeePermissions.canViewEvents;
    case 'crm':
      return employeePermissions.canViewCustomers;
    case 'settings':
      return employeePermissions.canViewAccountSettings;
    case 'messaging':
      return employeePermissions.canViewMessaging;
    case 'campaigns':
      return employeePermissions.canViewAdCampaigns;
    default:
      return false;
  }
}

export const authGuard: CanActivateFn = async (route, state) => {
  const accountService = inject(AccountService);
  const authService = inject(AuthService);
  const router = inject(Router);
  let employeePermissions: VenueEmployeePermissionDto;

  const accessToken = authService.getAccessToken();
  const refreshToken = authService.getRefreshToken();

  employeePermissions = await accountService.getUserPermissions();

  if (accessToken && !authService.isAccessTokenExpired()) {
    if (!!employeePermissions) {
      // Check if the user has access to the page they are trying to navigate to
      if (hasPermissionToAccessRoute(employeePermissions, route)) {
        return true;
      } else {
        // User does not have permission to access the route
        router.navigate(['/access-denied']);
        return false;
      }
    } else {
      // Access Token is valid and we don't have employee permissions so let it pass
      return true;
    }
  } else if (refreshToken) {
    // Attempt to refresh Access Token
    try {
      const refreshResponse = await authService.refreshToken();
      if (refreshResponse.AccessToken && refreshResponse.RefreshToken) {
        return true;
      }
    } catch (error) {
      console.error('Refresh token failed:', error);
    }
  }

  // If we reach here, redirect to login
  router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;
};
